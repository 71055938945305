.formWrapper {
	padding: $paddingDefault*1.5;
	border: 1px solid $c-concrete;
	background-color: #fff;

	h2 {
		margin-bottom: $marginDefault*1.5;
	}

	.button {
		margin: $marginDefault auto;
	}
}

.formSection + .formSection {
	margin-top: $marginDefault*2;
	padding-top: $paddingDefault*1.5;
	border-top: 1px solid $c-concrete;
}

label {
	display: block;
	//margin-bottom: $marginDefault*.25;
	@include fontsize($fs-formLabels);
	line-height: $lh-formLabels;
	//text-transform: uppercase;
	//color: $c-martinique;
	//font-weight: bold;

	&.required:after {
		content: '*';
		display: inline-block;
		font-weight: bold;
	}
}

select {
	text-transform: uppercase;
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
textarea,
select,
option {
	display: block;
	width: 100%;
	padding: $paddingDefault*.15 $paddingDefault*.45 $paddingDefault*.2;
	text-align: left;
	@include fontsize($fs-formInputs);
	line-height: $lh-formInputs;

	label + & {
		margin-top: $marginDefault * .3 !important;
	}
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
textarea,
select {
	border: 1px solid $c-concrete;


	&:focus {
		outline: none;
	}
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
textarea {
	background-color: $c-wildSand;
	color: $c-dustyGray;
	padding: $paddingDefault*1.2 $paddingDefault*1.25 $paddingDefault;
}

.field {
	margin-top: $marginDefault*.75;
}

.error {
	color: $c-error;
	margin-top: 0;
}
.errors {
	list-style-type: none;
	//margin: 2px 0 0;
	padding: 0;

	li {
		color: $c-error;
		margin-top: 0;
		//margin-bottom: 5px;
	}
}

input[type=checkbox] {
	margin-right: $marginDefault*.5;
}

.formInstructions {
	font-style: italic;
}