.listing {
	text-align: center;
	padding: $paddingDefault*1.5 0;
	margin-left: auto;
	margin-right: auto;

	@include query_min($bp-sm){
		padding-left: $paddingDefault;
		padding-right: $paddingDefault;
	}
}

.listing-image + .listing-info {
	margin-top: $marginDefault*2;
}

.listing-name {
	a {
		text-decoration: none;

		&:hover {
			color: $c-emerald;
		}
	}
}


.listing-sub {
	margin-top: $marginDefault*.75;
	line-height: 1.1;
	color: $c-doveGray !important;
}
.listing-sub--live {
	font-weight: bold;
}


.listing-description {
	margin-top: $marginDefault*1.2;
}

.listing-socialHolder {
	margin-top: $marginDefault*1.5;
}