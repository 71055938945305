@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.fadeOut {
	animation-name: fadeOut;
}
