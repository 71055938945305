.iconListing-image + .iconListing-content {
	margin-top: $marginDefault*2;
}

.iconListing-heading {
	padding-bottom: $paddingDefault*.5;
}

a.iconListing {
	text-decoration: none;
	display: block;

	.iconListing-heading {
		color: $c-emerald;
	}

	&:hover {
		.iconListing-heading {
			text-decoration: underline;
		}
	}
}