@font-face {
	font-family: $ff-icons;
	src:  url('#{$iconFontPath}/#{$ff-icons}.eot?#{$iconHash}');
	src:  url('#{$iconFontPath}/#{$ff-icons}.eot?#{$iconHash}#iefix') format('embedded-opentype'),
	url('#{$iconFontPath}/#{$ff-icons}.ttf?#{$iconHash}') format('truetype'),
	url('#{$iconFontPath}/#{$ff-icons}.woff?#{$iconHash}') format('woff'),
	url('#{$iconFontPath}/#{$ff-icons}.svg?#{$iconHash}##{$ff-icons}') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icon, %icon-font {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: $ff-icons !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-calendar {
	&:before {
		content: $icon-calendar;
	}
}
.icon-clock {
	&:before {
		content: $icon-clock;
	}
}
.icon-facebook {
	&:before {
		content: $icon-facebook;
	}
}
.icon-instagram {
	&:before {
		content: $icon-instagram;
	}
}
.icon-linkedin {
	&:before {
		content: $icon-linkedin;
	}
}
.icon-marker {
	&:before {
		content: $icon-marker;
	}
}
.icon-mobile {
	&:before {
		content: $icon-mobile;
	}
}
.icon-pinterest {
	&:before {
		content: $icon-pinterest;
	}
}
.icon-twitter {
	&:before {
		content: $icon-twitter;
	}
}
.icon-web {
	&:before {
		content: $icon-web;
	}
}
.icon-youngliving {
	&:before {
		content: $icon-youngliving;
	}
}
