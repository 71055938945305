.parallax-container {
	position: relative;
	overflow: hidden;
}

.parallax {
	position: absolute;
	top: 0;
	left: 0;
	height: 130%;
	width: 100%;
}